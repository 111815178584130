<template >
  <ion-page>
    <Header type="0" title="扫码装车查看"> </Header>

    <ion-content scroll-y="true" class="content">
      <div class="root">
        <div class="item">
          <div class="left"><span class="required">*</span>车辆编号</div>
          <div class="right">
            <input
              @input="scan"
              placeholder="请扫描车辆二维码"
              v-model="listQuery.vehicleCode"
            />
          </div>
        </div>
        <div class="item">
          <div class="left">试制任务单号</div>
          <div class="right">
            {{ listQuery.stageCode }}
          </div>
        </div>
      </div>
      <!-- 无数据  -->
      <div class="no_data" v-if="tableList.length == 0 && !islist">
        <img
          class="no_data_img no_data_top"
          src="@/assets/images/notDate/notSearch.png"
          alt
        />
        <p class="no_data_text">未查询到信息</p>
      </div>
      <!-- <entruck-card v-if="idx >= 0" :dataItem="tableList[idx]"></entruck-card> -->
      <div class="app-container" v-for="item in tableList"
          :key="item.id">
        <div
          class="item"
          style="border-bottom: 1px solid #e2e2e2; padding-bottom: 15px"
         
        >
          <div class="item-label">{{ item.partCode }}</div>
          <div class="title">{{ item.partNameCN }}</div>
          <div class="rate">{{ item.partVersion }}</div>
        </div>
        <div class="item">
          <div class="item-label">每车数量</div>
          <div class="item-content">{{ item.eachNum }}</div>
        </div>
        <div class="item">
          <div class="item-label">扫码数量</div>
          <div class="item-content">
            {{ item.installNum }}
          </div>
        </div>
      </div>
      <CardLoading v-if="islist" lodingType="1"></CardLoading>
    </ion-content>
    <scanner v-if="showScan" @closeScan="closeScan"></scanner>
  </ion-page>
</template>
<script>
import { alertController, modalController, ionAlert } from "@ionic/vue";
import Header from "@/components/Header.vue";
import uploadImg from "@/components/getPhoto";
import {
  getVehPartList,
  updateInstallNum,
  getVehicleState,
} from "@/api/entrucking/entrucking";
import entruckCard from "./entruckCard.vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import Utils from "@/utils/Utils.ts";
import scanner from "@/components/cordova/qrScanner.vue";
import SystemUtils from "@/utils/SystemUtils";
import listModal from "./listModal.vue";

export default {
  components: {
    Header,
    uploadImg,
    entruckCard,
    CardLoading,
    ionAlert,
    scanner,
    listModal,
  },
  data() {
    return {
      tableList: [],
      islist: false, // isloading
      isOpenRef: false, // 确认框
      idx: -1,
      listQuery: {
        stageId: "",
        vehicleCode: "",
        type: 1,
      },
      showScan: false,
    };
  },
  mounted() {},
  methods: {
    getList() {
      this.islist = true;
      getVehPartList(this.listQuery).then((res) => {
        this.islist = false;
        if (
          res.data.data &&
          res.data.data.isSuccess &&
          res.data.data.resultData.length > 0
        ) {
          this.tableList = res.data.data.resultData;
        } 
      });
    },
    scan(val) {
      var _this = this;
      this.$nextTick(() => {
        var scanVal = _this.listQuery.vehicleCode;
        if (scanVal.indexOf('"') > -1) {
          _this.listQuery.vehicleCode = "";
          if (
            scanVal.indexOf("stageId") > -1 &&
            scanVal.indexOf("vehicleCode") > -1
          ) {
            var contents = JSON.parse(
              "{" + scanVal.slice(scanVal.indexOf('"')) + "}"
            );
            _this.listQuery.stageId = contents.stageId;
            _this.listQuery.stageCode = contents.stageCode;
            _this.listQuery.vehicleCode = contents.vehicleCode;
            _this.tableList = [];
            _this.getList();
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-lingbujianxinxi {
  font-size: 48px;
}
ion-button {
  --background: #00529c;
}

.star {
  color: red;
  padding-top: px2rem(10);
}
.root:last-child {
  margin-top: px2rem(15);
}
.root {
  width: calc(100% #{px2rem(58)});
  height: auto;
  margin: px2rem(20) auto;
  background: #ffffff;
  border-radius: $border-radius;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: px2rem(20) px2rem(45);
  font-size: #{$default-font-size};
}
.lefttwo {
  width: px2rem(270) !important;
  height: auto !important;
  line-height: px2rem(38) !important;
  // text-align: left;
  color: $color !important;
}
.item {
  .title {
    width: 100% !important;
    font-size: px2rem(28) !important;
    font-weight: 700;
    ion-textarea {
      --placeholder-color: #8e8e8e;
      --placeholder-opacity: 1;
    }
  }
}
.uniline {
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  height: px2rem(88);
  display: flex;
  .left {
    width: 30%;
    line-height: px2rem(88);
    text-align: left;
  }
  .right {
    width: 70%;
    height: px2rem(88);
    text-align: right;
    --color: black;
    line-height: px2rem(88);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .iconfont {
      margin-left: px2rem(25);
      font-size: px2rem(12);
      color: #325cab;
    }
    ion-icon {
      margin-top: px2rem(10);
      width: px2rem(26);
      height: px2rem(26);
      color: #325cab;
    }
  }

  .ser {
    // 放大镜、、暂无
    font-size: px2rem(26);
  }
}
.uniline-last {
  border-width: 0;
}
.multi-line {
  margin-top: 1rem;
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  border-width: 0;
  text-align: left;
  .textarea {
    width: calc(100%-#{px2rem(24)+3rem});
    --padding-top: 0;
    margin-left: 3rem;
    --color: black;
  }
}
.moreproject {
  text-align: center;
  font-size: px2rem(24px);
  color: #325cab;
  margin-top: px2rem(20px);
}
.app-container {
  width: calc(100% - 2.5rem);
  height: auto;
  background: #ffffff;
  border-radius: 0.875rem;
  margin: 1.25rem auto;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: 1.25rem 2.8125rem;
  font-size: 1.625rem;
  div {
    padding: 5px 0;
  }
  .title {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
  }
  .rate {
    font-size: 15px;
    font-weight: 500;
    color: #1782e3;
  }
  ion-item {
    --background: #ffffff;
    --padding-start: 0;
  }
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-content {
      .addBorderRight {
        display: inline-block;
        padding-right: px2rem(20);
        margin-right: px2rem(20);
        height: px2rem(36);
        border-right: 2px solid rgba(151, 151, 151, 0.3);
        width: 95px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .item-label {
      width: 40%;
      font-size: 10px;
      opacity: 0.5;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
    }
  }
}
input {
  border: 0;
  outline: none;
  text-align: right;
}
</style>